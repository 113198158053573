<template>
  <div>
    <b-container class="p-4" id="sobre">
      <div v-animate-onscroll="{ down: 'animated pulse' }">
        <h2
          id="hist"
          class="text-center"
          style="color:#330667;font-weight: bold;margin-top:3em;"
        >
          NOSSA HISTÓRIA
        </h2>
        <br />
      </div>
    </b-container>
    <b-container class="corpo mt-5 p-4">
      <div v-animate-onscroll="{ down: 'animated bounceInLeft' }">
        <b-row class="linha">
          <b-col class="p-3 logo">
            <img id="logo1" src="@/assets/logo-spektra1.png" alt />
            <br />
          </b-col>
          <b-col class="textoCorpo">
            <p style="margin-top:7em;font-size: large;">
              A Spektra nasceu para solucionar desafios do mercado farmacêutico,
              dentre eles como produzir medicamentos não apenas com maior
              qualidade, mas principalmente com maior segurança.
              <br />
              <br />Fundada com o objetivo de colaborar ativamente com a
              evolução do conhecimento do mundo das impurezas em medicamentos no
              Brasil e Am. Latina.
              <br />
              <br />Nossa expertise em toxicologia associada à nossa experiência
              na indústria farmacêutica faz com que consigamos entender
              claramente a necessidade dos clientes, que muitas vezes não
              possuem ferramentas para solucioná-las.
              <br />
              <br />+ de 100 projetos submetidos e aprovados pela ANVISA e
              nenhum indeferimento no currículo. Sucesso como fruto de um
              trabalho de desenvolvimento de relações técnico-científicas no
              ambiente corporativo e na relação com a ANVISA, prova de que o
              relacionamento e o trabalho baseados em ciência, ética e
              transparência é a garantia de trabalhos e entregas sustentáveis.
              <br />
            </p>
          </b-col>
        </b-row>
      </div>
      <missao style="margin-top:-9em" />
      <div v-animate-onscroll="{ down: 'animated bounceInLeft' }">
        <b-container class="mt-5 p-5">
          <b-row>
            <b-col>
              <h2
                class="text-center"
                style="color:#330667;font-weight: bold;margin-top:3em;"
              >
                EQUIPE SPEKTRA
              </h2>
              <p style="margin-top:10em;font-size: large;">
                Para a entrega de soluções inovadoras e transformadoras aos
                nossos clientes, confiamos que nossa equipe, formada por grandes
                parceiros, seja um grande diferencial. Além de excepcionalmente
                qualificados para suas respectivas funções, a relação de
                confiança fundamentada no amor e união, permitem que a Spektra
                possa sonhar grande, se transformando .
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <br />
      <v-container class="p-5">
        <div
          v-animate-onscroll="{ down: 'animated bounceInRight' }"
          style="margin-top:8em"
        >
          <b-row class="textoCorpo1">
            <b-col id="personPic1" class="text-center p-4">
              <img src="@/assets/Mariah.jpg" style="width: 50%;" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/natanael-segretti/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Dra. Mariah Ultramari</i>
            </b-col>
            <b-col class="textoCorpo">
              <p style="font-size: large;">
                <br />
              </p>
              <h5>Mariah Ultramari, PhD</h5>
              <b>Fundadora e CEO</b>
              <br />
              <br />Formada em Farmácia Bioquímica na FOC, com doutorado em
              toxicologia pela FCF-USP sendo sua tese focada em Produtos de
              Degradação de medicamentos, a Mariah teve a oportunidade de
              participar e contribuir ativamente com as discussões para
              publicação da RDC nº53/2015. Essa experiência, além de torná-la
              referência no assunto, permitiu que ela conquistasse um networking
              respeitoso em todos os pilares que sustentam a Indústria
              Farmacêutica, sendo eles, Academia, Indústria e Agência
              Regulatória (Anvisa).
              <br />
              <br />Antes mesmo dela concluir seu doutorado, já recebia convites
              para trabalhar na Indústria. Quando chegou lá, teve a oportunidade
              de liderar a construção de um laboratório com as tecnologias mais
              inovadoras e uma equipe com 8 cientistas, reverter indeferimentos
              e responder muitas exigências da Anvisa apenas com justificativas
              técnico-científicas, participar como palestrante em congressos
              internacionais e se desenvolver nos melhores softwares e
              tecnologias disponíveis
              <br />
              <br />Depois de passar pelo mundo acadêmico e pela indústria, ela
              percebeu que a autonomia do empreendedorismo poderia aumentar suas
              possibilidades de contribuição com a comunidade científica e foi
              por isso que então ela decidiu fundar a SPEKTRA SOLUÇÕES
              CIENTÍFICO-REGULATÓRIAS, uma empresa que apesar de jovem, já
              conquistou grandes realizações, com mais de 200 relatórios
              entregues sem nenhuma devolutiva negativa.
            </b-col>
            <b-col id="personPic" class="text-center p-4">
              <img src="@/assets/Mariah.jpg" style="width: 50%;" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/mariah-spektra/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Dra. Mariah Ultramari</i>
            </b-col>
          </b-row>
        </div>
        <br />
        <br />
        <br />
        <div v-animate-onscroll="{ down: 'animated bounceInLeft' }">
          <b-row class="textoCorpo1">
            <b-col class="text-center p-4">
              <img src="@/assets/NATAN.jpg" style="width: 55%;" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/natanael-segretti/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Dr. Natan Segretti</i>
            </b-col>
            <b-col class="textoCorpo">
              <p style="font-size: large;">
                <br />
              </p>
              <h5>Dr. Natan Segretti, PhD</h5>
              <b>Sócio e CTO</b>
              <br />
              <br />Parceiro da Mariah no amor e na ciência, o Natan é sócio da
              Spektra e responsável pela constante busca por melhorias nos
              processos dos serviços prestados, com o objetivo de nos
              anteciparmos e mantermos o nível de excelência em constante
              evolução, sempre conectado às tendências internacionais e melhores
              ferramentas para entrega de soluções inovadoras.
              <br />
              <br />Graduado em Farmácia pela UniSantos, ele concluiu seu
              Doutorado pela USP em Química Medicinal. Teve também a
              oportunidade de realizar o "Doutorado Sanduíche" no Instituto de
              Pesquisas para Tuberculose, na Universidade de Illinois - Chicago,
              EUA, uma experiência engrandecedora tanto para seu desenvolvimento
              profissional quanto pessoal.
              <br />
              <br />A sua vivência na farmácia iniciou cedo, na drograria dos
              seus próprios pais ainda na época em que farmacêuticos eram
              reconhecidos por muitos como "médicos" da família. Chegou ao mundo
              corporativo como especialista de Química Orgânica e Analítica na
              Micronal S.A. e foi durante uma apresentação de prospecção com um
              dos seus clientes, que surgiu uma grande oportunidade para ele
              decolar a sua carreira na indústria, publicando importantes papers
              e realizando o depósito de patentes.
              <br />
              <br />Ele sempre acreditou e, sobretudo, respaldou o sonho que,
              inicialmente era da Mariah, mas que a partir de 2020 se tornou a
              realidade e propósito dos dois.
            </b-col>
          </b-row>
        </div>
        <br />
        <br />
        <br />
        <div v-animate-onscroll="{ down: 'animated bounceInRight' }">
          <b-row class="textoCorpo1">
            <b-col id="personPic1" class="text-center p-4">
              <img src="@/assets/Diego.jpeg" alt style="width:60%;height:95%" />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/diegozgomes/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>MSc Diego Zulkiewicz Gomes</i>
            </b-col>
            <b-col class="textoCorpo">
              <p style="font-size: large;">
                <br />
              </p>
              <h5>MSc Diego Zulkiewicz Gomes</h5>
              <b>Diretor Científico</b>
              <br />
              <br />Com expertise em Técnicas Analíticas, Desenvolvimento de
              Métodos e soluções Científico-Regulatória, o Diego atua com foco
              em estudos de Degradação de Fármacos (RDC 53/2015 - Anvisa),
              Extraíveis e Lixiviáveis e Impurezas Elementares (ICH Q3D).
              <br />
              <br />Ele é autoridade absoluta, quando o assunto é Cromatografia
              Líquida e Espectrometria de Massas (LC-DAD/Fl/ RI, LC-MS).
              <br />
              <br />Seus estudos científicos iniciaram com uma formação técnica
              em Química na ETE Getúlio Vargas. Tanto seu bacharel em Química
              com atribuições Tecnológicas, quanto seu Mestrado em Química
              Orgânica foram realizados na UFSCar, consolidando uma base técnica
              sólida que fomentou sua evolução no mundo corporativo.
              <br />
              <br />Com mais de 10 anos de experiência no segmento Químico e
              Farmacêutico, atuando em cromatografia líquida e gasosa e
              espectrometria de massas na área de Pesquisa e Desenvolvimento,
              ele se juntou a equipe Spektra no final de 2020 para contribuir
              com a expansão dos negócios na área de Impurezas. O conhecimento e
              domínio dos softwares da Lhasa, seu Networking Internacional e
              experiência na área permite que a Spektra construa a sua reputação
              como referência internacional no desenvolvimento de soluções
              Científico Regulatórias.
            </b-col>
            <b-col id="personPic" class="text-center p-4">
              <img src="@/assets/Diego.jpeg" alt style="width:90%;height:70%" />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/diegozgomes/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Dr. e MSc Diego Zulkiewicz Gomes</i>
            </b-col>
          </b-row>
        </div>
        <br />
        <br />
        <br />
        <div v-animate-onscroll="{ down: 'animated bounceInRight' }">
          <b-row class="textoCorpo1">
            <b-col class="text-center p-4">
              <img src="@/assets/CAROL.jpg" style="width: 75%;" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/carolina-neves-013a4030/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Carolina Neves</i>
            </b-col>
            <b-col class="textoCorpo">
              <p style="font-size: large;">
                <br />
              </p>
              <h5>Carolina Neves</h5>
              <b>Diretora Executiva</b>
              <br />
              <br />A Carol é formada em Administração de Empresas e tem Pós em
              Empreendedorismo Sustentável. A primeira a se juntar à Mariah na
              jornada do empreendedorismo da Spektra, ela é responsável pelo
              Administrativo, Comercial, Compliance, Financeiro, Jurídico,
              Contábil e MUITO MAIS!
              <br />
              <br />Com mais de 10 anos de experiência na área de Recursos
              Humanos, ela começou sua jornada em Desenvolvimento Humano no
              mercado financeiro, em uma renomada corretora de valores no Brasil
              (a Hedging Griffo), que foi vendida para o Banco Credit Suisse,
              onde ela trabalhou por mais de 6 anos e construiu uma sólida
              experiência em Gente e Gestão. A partir de 2014, ela teve a
              oportunidade de viver e trabalhar em Nova Iorque, ainda em
              Recursos Humanos, porém agora em uma das maiores Agências de
              Publicidade do mundo, a Omnicom Media Group!
              <br />
              <br />
              <br />
              <br />Em seu ano sabático, ela se formou como Professora de Yoga e
              sua Inteligência Emocional, desenvolvida a partir de várias
              vivências multidisciplinares, é a qualidade que faz da Carol uma
              profissional excepcional e que sabe viver o presente,, enfrentando
              e resolvendo tudo que está ao seu alcance, com praticidade e
              leveza. Ela acredita que construir um mundo melhor depende de cada
              um de nós e que, através do Desenvolvimento Humano é possível
              deixar um legado de prosperidade e abundância.
            </b-col>
          </b-row>
        </div>
        <br />
        <br />
        <br />
        <div v-animate-onscroll="{ down: 'animated bounceInLeft' }">
          <b-row class="textoCorpo1">
            <b-col id="personPic1" class="text-center p-4">
              <img src="@/assets/TONHAO.jpg" style="width: 50%;" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/jose-antonio-ultramari-a58693a6/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Antônio Ultramari</i>
            </b-col>
            <b-col class="textoCorpo">
              <p style="font-size: large;">
                <br />
              </p>
              <h5>Antônio Ultramari</h5>
              <b>Estagiário Presidente</b>
              <br />
              <br />Formado na Escola Superior de Administração e Negócios, a
              experiência profissional do Antônio começou com ferramentas que,
              atualmente, poucos conhecem: Telex, FAX, Lotus 123, Dbase, Word,
              MS-DOS.
              <br />
              <br />A sua vivência prática de décadas como empreendedor,
              dispensa credenciais e traz qualidades que não têm preço, mas tem
              muito valor.
              <br />
              <br />Ele se juntou ao time Spektra no final de 2019 e junto com a
              Carol, é responsável pelo setor administrativo. Em pouco tempo, a
              sua visão já provocou profundas reflexões e redirecionou as
              prioridades da empresa.
              <br />
              <br />O homem mais velho de 6 irmãos, marido de uma linda
              Portuguesa-Brasileira, pai de um casal de adultos independentes e
              tio da Mariah, o "Tonhão" na família é conhecido e respeitado como
              O Presidente!
              <br />
              <br />Mas aqui na Spektra, para mantermos o espírito jovem e
              divertido, sugerimos a ele o cargo de “Estagiário Presidente” e
              sabe qual foi sua resposta?
              <br />
              <br />"Para mim, isso tanto faz, eu vim para somar"
            </b-col>
            <b-col id="personPic" class="text-center p-4">
              <img src="@/assets/TONHAO.jpg" style="width: 50%;" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/jose-antonio-ultramari-a58693a6/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Antônio Ultramari</i>
            </b-col>
          </b-row>
        </div>
        <br />
        <br />
        <br />
        <div v-animate-onscroll="{ down: 'animated bounceInLeft' }">
          <b-row class="textoCorpo1">
            <b-col class="text-center p-4">
              <img src="@/assets/RAPHA.jpg" style="width:60%" alt />
              <br />
              <br />
              <b-link
                target="_blank"
                href="https://www.linkedin.com/in/raphael-ultramari-7043316a/"
              >
                <i
                  class="fab fa-linkedin fa-1x ml-1"
                  style="color:#330667; margin-right:.3em;"
                ></i>
              </b-link>
              <i>Raphael Ultramari</i>
            </b-col>
            <b-col class="textoCorpo">
              <p style="font-size: large;">
                <br />
              </p>
              <h5>Raphael Ultramari</h5>
              <b>Comunicação & Marketing</b>
              <br />
              <br />O Rapha é aquele cara que se você jogar uma palavra no ar,
              ele capta e te devolve milhões de idéias em diferentes formatos.
              Sua capacidade de sacar as mensagens e transformá-las em
              excelentes textos para uma comunicação simples e transparente é
              incontestável.
              <br />
              <br />Responsável pelo Marketing da Spektra, ele é o irmão do meio
              da Mariah e se juntou ao time para mostrar que não precisa ser
              sério para trabalhar com seriedade. Formado em Administração de
              Empresas, ele trabalhou mais de 10 anos na área comercial do
              mercado financeiro e foi sua habilidade em comunicação que abriu
              as portas para a carreira internacional na Wall Street, Nova
              Iorque, onde trabalhou por aproximadamente três anos e decidiu
              tirar um ano sabático para se dedicar à evolução espiritual.
              <br />
              <br />Professor de Yoga e apaixonado por esportes, ele é um
              entusiasta do Marketing Digital e acredita na capacidade do
              desenvolvimento pessoal e profissional a partir desse novo formato
              de estudos à distância, que chegou junto com 2020 e contribui em
              diferentes aspectos para a evolução da Spektra.
            </b-col>
          </b-row>
        </div>
        <br />
        <br />
        <br />
      </v-container>
    </b-container>
    <b-container class="p-4">
      <div v-animate-onscroll="{ down: 'animated pulse' }">
        <h2
          class="text-center"
          id="sustent"
          style="color:#330667;font-weight: bold;margin-top:3em;"
        >
          FUTURO PELO HOJE
        </h2>
        <br />
        <br />
      </div>
      <b-row class="text-center">
        <b-col>
          <div v-animate-onscroll="{ down: 'animated rubberBand' }">
            <img
              style="width:25%; padding:1em;"
              src="@/assets/sustent.png"
              alt
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col class="p-3" style="font-size: large;">
          O símbolo <b id="verde">verde</b> da nossa logo, representar
          assertividade e foco, também remete ao nosso compromisso com o meio
          ambiente. <br />Os serviços da Spektra são 100% baseados em métodos
          alternativos ao uso de animais para comprovação da segurança dos
          produtos. Deste modo, utilizamos ferramentas <i>in silico</i> como
          nossa principal abordagem associada à avalições de risco integrada.
          <br />Nos dedicamos a realizar todas as nossas atividades burocráticas
          com o menor gasto de energia e recursos possível. Por exemplo, a
          grande maioria dos nossos contratos são assinados eletronicamente,
          evitando assim, gasto de papel, de combustível para transporte do
          documento, dentre outros. <br />Nossos relatórios também são todos
          digitais. <br />Já nos recusamos a assinar documentos físicos por
          conta desta nossa política !
        </b-col>
      </b-row>
    </b-container>
    <br />
    <br />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Missao from "../components/Missao.vue";
export default {
  name: "Sobre",
  components: {
    Footer,
    Missao,
  },
};
</script>
<style scoped>
p {
  vertical-align: top;
  text-indent: 2em;
}
#logo1 {
  opacity: 0.4;
  width: 70%;
}
i {
  font-size: small;
}
#verde {
  color: #98d02f;
}
#personPic1 {
  display: none;
}
@media only screen and (max-width: 768px) {
  .logo {
    text-align: center !important;
  }
  #logo1 {
    opacity: 0.4;
    width: 70% !important;
    margin-bottom: -6em;
  }
  .corpo {
    padding: 0 !important;
  }
  .linha {
    display: block !important;
  }
  .textoCorpo {
    padding: 2em;
  }
  .textoCorpo1 {
    display: block;
  }
  #personPic {
    display: none;
  }
  #personPic1 {
    display: block;
  }
}
</style>
